<!-- 文章总页面 -->
<template>
    <div>
        <CheckLogin />
        <SldHomeTopSearch />
        <NavCatHeader />
        <div class="article_line"></div>
     
        <div class="article_wrap">
            <div class="nav">
                <div v-if="lang == 'zh'" class="article_container">
                    <div class="article_tit">
                        在 Dzon 服务中链接和使用卡的条件
                    </div>
                    <div class="article_time">
                        发布日期 2022 年 6 月 30 日
                    </div>
                    <div class="article_sub_tit">
                        1. 术语和定义
                    </div>
                    <div class="article_sub_desc">
                        Dzon 服务文件- 管理客户使用 Dzon 服务条款并发布在网站和/或相应 Dzon 服务上的协议和其他文件。<br>
                        客户是指已通过验证并按照 Dzon ID 使用条款使用 Dzon ID 的人。<br>
                        个人账户- Dzon 上的客户个人页面，注册客户的数据。<br>
                        关联卡- 已输入数据、通过安全的“3-D Secure”授权协议授权并由客户保存在个人账户中的银行卡，用于 Dzon 服务中的非现金支付。
                    </div>
                    <div class="article_sub_tit">
                        2.一般条件
                    </div>
                    <div class="article_sub_desc">
                        2.1. Dzon 为客户提供了在 Dzon 服务中链接银行卡以进行非现金支付的机会，以及根据 Dzon 服务中链接卡的可用性使用所提供的其他功能。<br>
                        2.2. 客户通过指定 Dzon 服务中显示的相应付款表格提供的数据，将其银行卡与其个人账户关联起来，该服务的功能允许关联银行卡，并使用“3-D Secure”技术进行一次性授权。执行上述操作后，校验和将被锁定在关联卡上以进行验证。该金额不会被扣除，仅用于确认关联卡的有效性。确认关联卡后，它将保存在您的个人帐户中，并可在 Dzon 服务中作为单独的付款方式使用。<br>
                        2.3. Dzon 根据 PCI DSS（支付卡行业数据安全标准）标准的要求以及制定的政策、程序和数据存储流程处理（包括收集、存储和保护）客户关联卡的数据。<br>
                        2.4. 本条款中提及的文件的规定以及支付技术的使用，关联卡的数据存储在支付提供商一侧，并用于将来使用安全的 3-D 安全授权协议进行未经授权的支付，且无需输入关联卡验证码 (CVV/CVC)。<br>
                        2.5. 客户在 Dzon 服务上下相应订单时（点击“在线支付”按钮或 Dzon 服务功能提供的其他类似按钮）并选择付款方式，即可通过关联卡进行付款“已保存的卡”包含关联卡的相应详细信息，不需要额外的付款确认。根据客户的订单（包括持续订单（定期付款））从关联卡中注销资金，以根据 Dzon 服务的相关文件从 Dzon 服务中选定的关联卡转移资金。<br>
                        2.6. Dzon 有权要求，且客户有义务提供确认使用关联卡合法性的信息和/或文件。如果未能根据要求提供信息和/或文件，Dzon 有权限制和/或阻止客户链接银行卡和/或使用之前在 Dzon 服务中链接的卡的能力。<br>
                        2.7. 在某些 Dzon 服务中，由于功能和/或技术特点，客户可能无法使用关联卡进行支付。<br>
                        2.8. 客户同意接收与链接卡的使用相关的信息消息。<br>
                        2.9. 客户同意，在使用 Dzon ID 进行适当授权的情况下，使用关联卡进行的所有付款和其他操作均被视为由客户进行。如果未经授权的人从关联卡中注销资金，客户有权联系发行关联卡的银行。
                    </div>
                    <div class="article_sub_tit">
                        3. 限制、责任和保证
                    </div>
                    <div class="article_sub_desc">
                        3.1. 关联卡的功能以及条款中指定的其他功能均“按原样”提供；Dzon 不负责其是否符合客户的期望，也不保证其持续的性能和可用性。<br>
                        3.2. 对于客户因 Dzon 无法控制的原因而无法添加银行卡的情况，Dzon 不承担任何责任，并且不保证添加此类卡用于 Dzon 服务。<br>
                        3.3. Dzon 有权自行决定并在不透露原因的情况下限制和/或阻止客户在 Dzon 服务中链接银行卡和/或使用之前链接的卡的能力。<br>
                        3.4. 除非 Dzon 服务条款和/或文件中另有明确规定，否则 Dzon 不对客户使用关联卡进行的交易或其他行为负责，并且无权接受任何索赔或解决与此相关的争议。<br>
                        3.5. 通过接受条款，客户确认并保证：<br>
                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;·他是具有法律能力的个人，有权使用关联卡进行交易，或已获得哈萨克斯坦立法规定的法定代表人的同意；<br>
                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;·向他提供有关以其名义发行的有效银行卡的可靠信息；<br>
                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;·遵守哈萨克斯坦法律、支付系统和发行关联卡的银行的要求，包括非现金支付方面的要求；<br>
                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;·使用关联卡不会违反哈萨克斯坦法律、本条款，或用于实施其他非法行为；<br>
                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;·这将提供合理的措施来保护个人账户，防止第三方未经授权访问客户的个人账户，包括使用关联卡进行付款的目的；<br>
                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;·他熟悉这些条款、客户使用的 Dzon 服务的文件、从关联卡中注销资金的程序、相关货物/工程/服务的成本以及与使用 Dzon 服务相关的其他物质条件联动卡。
                    </div>
                    <div class="article_sub_tit">
                        4. 最终条款
                    </div>
                    <div class="article_sub_desc">
                        4.1. Dzon 可能会更改这些条款，恕不另行通知客户。新版本的条款自在互联网上的条款地址发布之日起生效。<br>
                        4.2. 如果客户不同意本条款或本条款中提及的文件的规定，包括与其变更相关的规定，客户有权删除个人账户中的关联卡数据，除非相关文件另有规定。客户接受 Dzon 服务。
                    </div>

                </div>

                <div v-if="lang == 'ru'" class="article_container">
                    <div class="article_tit">
                        Условия привязки и использования карт в сервисе Dzon
                    </div>
                    <div class="article_time">
                        Дата публикации: 30 июня 2022 г.
                    </div>
                    <div class="article_sub_tit">
                        1. Термины и определения
                    </div>
                    <div class="article_sub_desc">
                        Документы Сервиса Dzon - Соглашения и другие документы, регулирующие использование Клиентом Условий предоставления услуг Dzon и размещенные на Сайте и/или в соответствующем Сервисе Dzon.<br>
                        Клиент - лицо, прошедшее верификацию и использующее Dzon ID в соответствии с Условиями использования Dzon ID.<br>
                        Личный кабинет - персональная страница клиента на Dzon, на которой зарегистрированы данные клиента.<br>
                        Привязанная карта - банковская карта, данные которой введены и авторизованы с помощью безопасного соглашения об авторизации «3-D Secure» и которая хранится у Клиента в его личном кабинете для осуществления безналичных платежей в сервисе Dzon.
                    </div>
                    <div class="article_sub_tit">
                        2. Общие условия
                    </div>
                    <div class="article_sub_desc">
                        2.1. Dzon предоставляет Клиенту возможность привязать банковскую карту в Сервисе Dzon для осуществления безналичных платежей, а также использовать другие предоставляемые функции в зависимости от наличия привязанной карты в Сервисе Dzon.<br>
                        2.2. Клиент привязывает свою банковскую карту к лицевому счету путем указания данных, предусмотренных соответствующей платежной формой, отображаемой в сервисе Dzon. Сервис позволяет привязать банковские карты и провести одноразовую авторизацию с использованием «3-D Secure». После этого чек будет заблокирован на привязанной карте для проверки. Сумма не будет списана и будет использована только для подтверждения действительности привязанной карты. После подтверждения карта будет сохранена в личном кабинете и может быть использована как отдельный способ оплаты в сервисе Dzon.<br>
                        2.3. Dzon обрабатывает (включая сбор, хранение и защиту) данные с привязанных карт Клиента в соответствии с требованиями стандарта PCI DSS (Payment Card Industry Data Security Standard) и установленными политиками, процедурами и процессами хранения данных.<br>
                        2.4. В соответствии с положениями документов, упомянутых в настоящих Условиях, и использованием платежных технологий данные Привязанной карты хранятся на стороне Поставщика платежей и используются для дальнейшего применения защищенных протоколов авторизации 3-D Secure для осуществления неавторизованных платежей без необходимости ввода кода верификации Привязанной карты (CVV/CVC).<br>
                        2.5. Оплата с помощью привязанной карты производится при оформлении Клиентом соответствующего заказа на сервисе Dzon (путем нажатия кнопки "Оплатить онлайн" или любой другой аналогичной кнопки, предусмотренной функционалом сервиса Dzon) и выборе способа оплаты. "Сохраненные карты" содержат реквизиты привязанной карты и не требуют дополнительного подтверждения платежа. Списание денежных средств с привязанной карты осуществляется на основании распоряжений Клиента (в том числе продолжающихся распоряжений (рекуррентных платежей)), а зачисление денежных средств с выбранной привязанной карты в сервисе Dzon - на основании соответствующих документов сервиса Dzon.<br>
                        2.6. Dzon имеет право запросить, а Клиент обязан предоставить информацию и/или документы, подтверждающие правомерность использования привязанной карты. В случае непредоставления запрашиваемой информации и/или документов Dzon имеет право ограничить и/или не допустить Клиента к привязке Банковской карты и/или использованию Карты, ранее привязанной к Сервису Dzon.<br>
                        2.7. В некоторых сервисах Dzon, в силу функциональных и/или технических особенностей, Клиент может не иметь возможности осуществлять платежи с использованием привязанной карты.<br>
                        2.8. Клиент соглашается получать сообщения об использовании привязанной карты.<br>
                        2.9. Клиент соглашается с тем, что все платежи и иные операции, осуществляемые с использованием Привязанной карты, считаются осуществленными Клиентом в случае надлежащей авторизации с использованием Dzon ID. В случае списания денежных средств с Привязанной карты неуполномоченным лицом Клиент вправе обратиться в банк-эмитент Привязанной карты.
                    </div>
                    <div class="article_sub_tit">
                        3. Ограничения, ответственность и гарантии
                    </div>
                    <div class="article_sub_desc">
                        3.1. Функциональные возможности Привязанной карты и другие функциональные возможности, указанные в Условиях, предоставляются "как есть"; Dzon не гарантирует, что они соответствуют ожиданиям Клиента, а также не гарантирует их непрерывность и доступность.<br>
                        3.2. Dzon не несет ответственности за невозможность добавления Клиентом банковской карты по причинам, не зависящим от Dzon, и не гарантирует добавление таких карт для использования в Услугах Dzon.<br>
                        3.3. Dzon оставляет за собой право, по своему усмотрению и без раскрытия причин, ограничить и/или запретить Клиенту привязывать карты и/или использовать ранее привязанные карты в Услугах Dzon.<br>
                        3.4. Если иное прямо не указано в Условиях обслуживания Dzon и/или документации, Dzon не несет ответственности за операции или иные действия, совершенные Клиентом с использованием Привязанной карты, и не уполномочен принимать какие-либо претензии или разрешать споры в связи с ними.<br>
                        3.5. Принимая Условия, Клиент подтверждает и гарантирует:<br>
                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;·Он является дееспособным физическим лицом и уполномочен использовать привязанную карточку для совершения операций, либо получил согласие законного представителя в порядке, предусмотренном законодательством Казахстана;<br>
                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;·Предоставить ему достоверную информацию о действующих банковских картах, выпущенных на его имя;<br>
                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;·Соблюдать требования законодательства Казахстана, платежной системы и банков-эмитентов привязанных карточек, в том числе в части безналичных расчетов;<br>
                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;·Использование привязанной карты не будет нарушать законодательство Казахстана, настоящие Условия, а также не будет использоваться для совершения иных противоправных действий;<br>
                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;·Это обеспечит разумные меры по защите персональных счетов от несанкционированного доступа третьих лиц к персональным счетам клиентов, в том числе для осуществления платежей с использованием привязанных карт;<br>
                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;·Он ознакомлен с этими условиями, документацией по использованию клиентом услуг Dzon, порядком списания средств с привязанной карты, стоимостью рассматриваемого товара/работы/услуги и другими существенными условиями, связанными с использованием услуг Dzon по привязанной карте.
                    </div>
                    <div class="article_sub_tit">
                        4. Заключительные положения
                    </div>
                    <div class="article_sub_desc">
                        4.1. Dzon может изменять настоящие Условия без предварительного уведомления. Новая редакция Условий вступает в силу с момента ее размещения по адресу Условий в сети Интернет.<br>
                        4.2. Если Клиент не согласен с положениями настоящих Условий или указанных в них документов, в том числе с положениями, связанными с их изменением, Клиент вправе удалить Привязанные данные карты из Лицевого счета, если иное не предусмотрено соответствующими документами. Клиент принимает Сервис Dzon.
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>
<script>
import SldHomeTopSearch from "../../components/SldHomeTopSearch";
import NavCatHeader from "../../components/NavCatHeader";
import { ElBreadcrumb, ElBreadcrumbItem } from "element-plus";
import { getCurrentInstance, reactive, ref, onMounted, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import SldCommonEmpty from '@/components/SldCommonEmpty';
export default {
    name: 'buyArticle',
    components: {
        ElBreadcrumb,
        ElBreadcrumbItem,
        SldHomeTopSearch,
        NavCatHeader,
        SldCommonEmpty,
    },
    setup() {
        const { proxy } = getCurrentInstance();
        const L = proxy.$getCurLanguage();
        const route = useRoute();
        const lang = ref('') 

        onMounted(() => {
            lang.value = localStorage.getItem('languageSet') || 'ru'
        });

        watchEffect(() => {
        });

        return { 
            L,
            lang
        }

    }
}
</script>

<style lang="scss" scoped>
.article_line {
    border-top: 2px solid var(--color_main);
}

.headPath {
    width: 1200px;
    margin: 0 auto;
}

.article_wrap {
    background-image: url("../../assets/article/bg.png");
    padding-top: 10px;
    padding-bottom: 50px;
    height: 100%;

    background-repeat: no-repeat;
    background-position: top top;
    background-size: cover;
}

.nav {

    width: 1200px;
    margin: 0 auto;
    background: #fff;
    padding: 24px;

    .article_container {
        // display: flex;
        line-height: 30px;
        .article_tit{
            font-size: 30px;
            font-weight: bold;
        }
        .article_time{
            color: #666;
            line-height: 40px;
        }
        .article_sub_tit{
            font-size: 24px;
            line-height: 50px;
        }
        .article_sub_desc{
            font-size: 16px;
        }
    }
}

.sld_common_empty {
    padding-top: 160px !important;
    background-color: #FFFFFF;
}
</style>